*{
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1; 
  
}

@media only screen and (max-width:600px) {
    .main-div{
      padding: 40px 10px 10px 40px
    }
}

@media only screen and (max-width:999px) {
    .sec_div_content{
      display: block;
      
    }
}